import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../env";
import { CloudUpload } from "lucide-react";
import { Button } from "@mui/material";
import "./style.css";
import Chip from "@mui/material/Chip";

const sharedOptions = [
  { value: "1", label: "Yes" },
  { value: "2", label: "No" },
];

export default function UploadSlideModal({
  open,
  setOpen,
  setRefresh,
  currentCaseId,
  setUploadProgress,
  setTimeLeft,
}) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CaseId: currentCaseId,
      SlideName: "",
      Shared: "1",
      file: null,
    },
    mode: "onBlur",
  });
  const [Tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [fileName, setFileName] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setOpen(false);
    reset();
    setTags([]);
    setFileName("");
    setErrorMessage("");
    setInputValue("");
  };

  const handleAddTag = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      const trimmedTag = inputValue.trim();
      if (!Tags.includes(trimmedTag)) {
        setTags([...Tags, trimmedTag]);
        setErrorMessage("");
      } else {
        setErrorMessage("Tag already exists!");
      }
      setInputValue("");
      e.preventDefault();
    }
  };

  const handleDeleteTag = (tagToDelete) => {
    setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
  };

  const TileGeneration = (imageUrl) => {
    fetch("https://tilegen.genesysailabs.com/generate-tiles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        "s3-bucket-name": "path-image",
        "s3-src": imageUrl,
        "s3-dest": `${currentCaseId}/wsi-dzi`,
        "s3-thump": `${currentCaseId}`,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Tile generation successful:", data);
      })
      .catch((error) => {
        console.error("Error in tile generation:", error);
      });
  };

  const onSubmit = (formData) => {
    if (Tags.length === 0) {
      setErrorMessage("Please add at least one tag");
      return;
    }

    setIsSaving(true);
    const formDataWithFile = new FormData();

    // Append form fields
    Object.keys(formData).forEach((key) => {
      formDataWithFile.append(key, formData[key]);
    });

    // Append tags as a JSON array
    formDataWithFile.append("Tags", JSON.stringify(Tags));

    const xhr = new XMLHttpRequest();
    xhr.open("POST", `${API_URL}slides/`, true);
    xhr.setRequestHeader(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    const startTime = Date.now();
    handleClose();

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setUploadProgress(percentComplete);

        const uploadSpeed = event.loaded / (Date.now() - startTime);
        const timeLeft = (event.total - event.loaded) / uploadSpeed / 1000;
        setTimeLeft(Math.round(timeLeft));
      }
    };

    xhr.onload = () => {
      setIsSaving(false);
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        console.log("Success:", data.ImageUrl);
        TileGeneration(data.ImageUrl);
        reset();
        setRefresh((prev) => !prev);
      } else {
        console.error("Error:", xhr.statusText);
        setErrorMessage("Upload failed. Please try again.");
      }
    };

    xhr.onerror = () => {
      setIsSaving(false);
      console.error("Error:", xhr.statusText);
      setErrorMessage("Upload failed. Please try again.");
    };

    xhr.send(formDataWithFile);
  };

  const renderTextField = (
    name,
    label,
    type = "text",
    select = false,
    options = [],
    validationRules = {}
  ) => (
    <Grid item xs={12}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <TextField
            fullWidth
            type={type}
            variant="outlined"
            sx={{
              backgroundColor: "white",
              borderRadius: "12px",
              border: "none",
              height: "40px",
              "& .MuiInputBase-root": {
                height: "100%",
              },
            }}
            select={select}
            {...field}
            required
            error={!!errors[name]}
            helperText={errors[name]?.message}
          >
            {select &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </Grid>
  );

  const renderTagsField = (
    name,
    label,
    validationRules = {},
    Tags,
    setTags,
    errorMessage
  ) => (
    <Grid item xs={12}>
      <label className="label">{label}*</label>
      <TextField
        fullWidth
        variant="outlined"
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          border: "0",
          height: "40px",
          "& .MuiInputBase-root": {
            height: "100%",
            borderRadius: "12px",
          },
        }}
        placeholder="Add a tag and press Enter"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleAddTag}
        onFocus={(e) =>
          e.target.setSelectionRange(inputValue.length, inputValue.length)
        }
        InputProps={{
          startAdornment: (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: "8px",
                maxWidth: "100%",
                borderRadius: "12px",
              }}
            >
              {Tags?.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag}
                  variant="outlined"
                  onDelete={() => handleDeleteTag(tag)}
                  size="small"
                  sx={{ margin: "2px", fontSize: "14px", borderRadius: "12px" }}
                />
              ))}
            </div>
          ),
        }}
        error={!!errorMessage}
        helperText={errorMessage}
      />
    </Grid>
  );

  const renderFileField = (name, label) => (
    <Grid item xs={12}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        rules={{ required: "File is required" }}
        render={({ field }) => (
          <div>
            <input
              type="file"
              id="file-upload"
              accept=".svs,.dcm,.dicom,.tif,.tiff,.jpg,.jpeg,.png"
              style={{ display: "none" }}
              onChange={(e) => {
                field.onChange(e.target.files[0]);
                setFileName(e.target.files[0]?.name || "");
              }}
              required
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
                sx={{
                  backgroundColor: "white",
                  color: "#333",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                }}
              >
                Choose File
              </Button>
            </label>
            {fileName && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: {fileName}
              </Typography>
            )}
            {errors[name] && (
              <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                {errors[name]?.message}
              </Typography>
            )}
          </div>
        )}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="modal-box-upload" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon sx={{ fontSize: "18px" }} />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              Upload Slide
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={3}>
                {renderTextField("SlideName", "Slide Name", "text", false, [], {
                  required: "Slide Name is required",
                })}
                {renderTagsField(
                  "Tags",
                  "Tags",
                  { required: "Please add at least one tag" },
                  Tags,
                  setTags,
                  errorMessage
                )}
                {renderTextField(
                  "Shared",
                  "Shared",
                  "text",
                  true,
                  sharedOptions,
                  {
                    required: "Please select an option",
                  }
                )}
                {renderFileField("file", "File")}
              </Grid>
            </Box>
            <div className="submit-div">
              <div
                className="cancel-div"
                onClick={handleClose}
                style={{ width: "130px" }}
              >
                Cancel
              </div>
              <div
                className="save-div"
                onClick={handleSubmit(onSubmit)}
                style={{ position: "relative", width: "130px" }}
              >
                {isSaving && <div className="spinner"></div>}
                {isSaving ? "Saving..." : "Save"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
