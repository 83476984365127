import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";
import { API_URL } from "../../env";

const specializations = [
  { value: "Cardiology", label: "Cardiology" },
  { value: "Neurology", label: "Neurology" },
  { value: "Orthopedics", label: "Orthopedics" },
  { value: "Cytology", label: "Cytology" },
  { value: "Histopathology", label: "Histopathology" },
  { value: "Hematology", label: "Hematology" },
  { value: "Clinical pathology", label: "Clinical pathology" },
  { value: "Molecular pathology", label: "Molecular pathology" },
  { value: "Microbiology", label: "Microbiology" },
  { value: "Biochemistry", label: "Biochemistry" },
  { value: "Veterinary pathology", label: "Veterinary pathology" },
];
const priorityOptions = [
  { value: "1", label: "Low" },
  { value: "2", label: "Medium" },
  { value: "3", label: "High" },
];
const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];

export default function CaseModal({
  open,
  setOpen,
  setRefresh,
  isEditMode,
  setIsEditMode,
  caseData,
  onSave,
}) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CaseName: "",
      CaseDescription: "",
      HospitalName: "",
      CaseNo: "",
      Specialization: "",
      Site: "",
      PatientName: "",
      PatientId: "",
      Age: "",
      Gender: "",
      AssignTo: "",
      Priority: "",
      Status: true,
      MedicalHistory: "",
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [users, setUsers] = useState([]);
  console.log(isEditMode, ">>>>>>>>>>>>>>>>");

  useEffect(() => {
    fetch(`${API_URL}cases/getusers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleClose = () => {
    setIsEditMode(false);
    setOpen(false);
  };
  useEffect(() => {
    if (isEditMode && caseData) {
      reset({
        CaseName: caseData.CaseName || "",
        CaseDescription: caseData.CaseDescription || "",
        HospitalName: caseData.HospitalName || "",
        CaseNo: caseData.CaseNo || "",
        HospitalId: caseData.HospitalId,
        Slides: caseData.Slides,
        FileSize: caseData.FileSize,

        Specialization: caseData.Specialization || "",
        Site: caseData.Site || "",
        PatientName: caseData.PatientName || "",
        PatientStrId: caseData.PatientStrId || "",
        Age: caseData.Age || "",
        Gender: caseData.Gender || "",
        AssignTo: caseData.AssignTo || "",
        Priority: caseData.Priority || "",
        Status: caseData.Status || true,
        MedicalHistory: caseData.MedicalHistory || "",
      });
    } else {
      reset({
        CaseName: "",
        CaseDescription: "",
        HospitalName: "",
        CaseNo: "",
        Specialization: "",
        Site: "",
        PatientName: "",
        PatientStrId: "",
        Age: "",
        Gender: "",
        AssignTo: "",
        Priority: "",
        Status: true,
        MedicalHistory: "",
      });
    }
  }, [isEditMode, caseData, reset]);

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const onSubmit = (formData) => {
    setIsSaving(true);
    const submissionData = {
      ...formData,
      Age: formData.Age ? parseInt(formData.Age, 10) : null,
    };

    const requestUrl = isEditMode
      ? `${API_URL}cases/${caseData.CaseId}`
      : `${API_URL}cases`;
    const requestMethod = isEditMode ? "PUT" : "POST";

    fetch(requestUrl, {
      method: requestMethod,
      body: JSON.stringify(submissionData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        reset();
        setRefresh((prev) => !prev);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSaving(false);
      });
    setIsEditMode(false);
  };

  const renderTextField = (
    name,
    label,
    type = "text",
    select = false,
    options = []
  ) => (
    <Grid item xs={6}>
      <label className="label">{label}*</label>
      <Controller
        name={name}
        control={control}
        rules={{ required: `${label} is required` }}
        render={({ field }) => (
          <TextField
            fullWidth
            type={type}
            variant="outlined"
            sx={textFieldStyles}
            select={select}
            {...field}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            required
          >
            {select &&
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="modal-box" sx={{ p: 4 }}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                width: 25,
                height: 25,
                borderRadius: "50%",
                backgroundColor: "white",
                color: "black",
                padding: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {isEditMode ? "Edit Case" : "Create New Case"}
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                {renderTextField("CaseName", "Case Name")}
                {renderTextField("CaseNo", "Case No")}
                <Grid item xs={12}>
                  <label
                    className="label"
                    style={{
                      marginTop: "4px",
                      marginBottom: "2px",
                      display: "block",
                    }}
                  >
                    Case Description*
                  </label>
                  <Controller
                    name="CaseDescription"
                    control={control}
                    rules={{ required: "Case Description is required" }}
                    render={({ field }) => (
                      <div>
                        <TextField
                          fullWidth
                          variant="outlined"
                          multiline
                          rows={1}
                          error={!!errors.CaseDescription}
                          sx={{
                            ...textFieldStyles,
                            width: "97%",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "12px",
                              "&.Mui-focused": {
                                borderColor: "#1976d2",
                              },
                              "&.Mui-error": {
                                borderColor: "#d32f2f",
                                borderRadius: "12px",
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              padding: "10px",
                              borderRadius: "12px",
                            },
                          }}
                          {...field}
                        />
                        {errors.CaseDescription && (
                          <span
                            style={{
                              color: "#d32f2f",
                              fontSize: "12.5px",
                              marginTop: "4px",
                              marginLeft: "13px",
                              display: "block",
                            }}
                          >
                            {errors.CaseDescription.message}
                          </span>
                        )}
                      </div>
                    )}
                  />
                </Grid>
                {renderTextField("HospitalName", "Hospital Name")}
                {renderTextField("MedicalHistory", "Medical History")}
                {renderTextField(
                  "AssignTo",
                  "Assign To",
                  "text",
                  true,
                  users.map((user) => ({
                    value: user.UserId,
                    label: user.FullName,
                  }))
                )}
                {renderTextField(
                  "Specialization",
                  "Specialization",
                  "text",
                  true,
                  specializations
                )}
                {renderTextField("PatientName", "Patient Name")}
                {renderTextField("Age", "Age", "number")}
                {renderTextField(
                  "Gender",
                  "Gender",
                  "text",
                  true,
                  genderOptions
                )}
                {renderTextField("PatientStrId", "Patient Id")}
                {renderTextField("Site", "Site")}
                {renderTextField(
                  "Priority",
                  "Priority",
                  "text",
                  true,
                  priorityOptions
                )}
              </Grid>
            </Box>
            <div className="submit-div" style={{}}>
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              <div className="save-div" onClick={handleSubmit(onSubmit)}>
                {isSaving && <div className="spinner"></div>}
                {isSaving ? "Saving..." : "Save"}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
