import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Checkbox,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
  Card,
  CardContent,
  Grid,
  Pagination,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  NavigateNext,
  NavigateBefore,
} from "@mui/icons-material";
import { API_URL } from "../../env";

const SelectSlidesModal = ({
  open,
  onClose,
  onSelect,
  caseBoxId,
  onConfirm,
}) => {
  const [cases, setCases] = useState([]);
  const [expandedCases, setExpandedCases] = useState({});
  const [caseSlides, setCaseSlides] = useState({});
  const [selectedSlides, setSelectedSlides] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingSlides, setLoadingSlides] = useState({});
  const [submitting, setSubmitting] = useState(false);

  // Pagination states
  const [casePage, setCasePage] = useState(1);
  const [caseTotalPages, setCaseTotalPages] = useState(1);
  const [slidePages, setSlidePages] = useState({});
  const [slideTotalPages, setSlideTotalPages] = useState({});

  // Fetch cases with pagination
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await fetch(`${API_URL}cases/?page=${casePage}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        const data = await response.json();
        setCases(data.cases || []);
        setCaseTotalPages(data.total_pages || 1);
      } catch (error) {
        console.error("Error fetching cases:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCases();
    }
  }, [open, casePage]);

  // Fetch slides for a specific case with pagination
  const fetchSlides = async (caseId, page = 1) => {
    setLoadingSlides((prev) => ({ ...prev, [caseId]: true }));
    try {
      const response = await fetch(`${API_URL}slides/${caseId}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const data = await response.json();
      setCaseSlides((prev) => ({
        ...prev,
        [caseId]: data.slides || [],
      }));
      setSlideTotalPages((prev) => ({
        ...prev,
        [caseId]: data.total_pages || 1,
      }));
    } catch (error) {
      console.error(`Error fetching slides for case ${caseId}:`, error);
    } finally {
      setLoadingSlides((prev) => ({ ...prev, [caseId]: false }));
    }
  };

  // Handle case expansion
  const handleCaseExpand = (caseId) => {
    setExpandedCases((prev) => {
      const newState = { ...prev, [caseId]: !prev[caseId] };
      if (newState[caseId] && !caseSlides[caseId]) {
        fetchSlides(caseId, slidePages[caseId] || 1);
      }
      return newState;
    });
  };

  // Handle slide pagination
  const handleSlidePagination = (caseId, page) => {
    setSlidePages((prev) => ({ ...prev, [caseId]: page }));
    fetchSlides(caseId, page);
  };

  // Handle slide selection
  const handleSlideSelect = (caseId, slide) => {
    setSelectedSlides((prev) => {
      const caseSelections = prev[caseId] || [];
      const isSelected = caseSelections.some(
        (s) => s.SlideId === slide.SlideId
      );

      const newCaseSelections = isSelected
        ? caseSelections.filter((s) => s.SlideId !== slide.SlideId)
        : [...caseSelections, slide];

      return {
        ...prev,
        [caseId]: newCaseSelections,
      };
    });
  };

  // Check if a slide is selected
  const isSlideSelected = (caseId, slideId) => {
    return selectedSlides[caseId]?.some((s) => s.SlideId === slideId) || false;
  };

  // Get total number of selected slides
  const getSelectedCount = () => {
    return Object.values(selectedSlides).reduce(
      (total, slides) => total + slides.length,
      0
    );
  };

  // Handle modal close
  const handleClose = () => {
    setSelectedSlides({});
    setExpandedCases({});
    setCaseSlides({});
    setCasePage(1);
    setSlidePages({});
    onClose();
  };

  // Handle confirm selection
  const handleConfirm = async () => {
    const selectedSlidesArray = Object.values(selectedSlides).flat();
    setSubmitting(true);

    try {
      const response = await fetch(`${API_URL}case_box_images/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          CaseBoxId: caseBoxId,
          Slides: selectedSlidesArray.map((slide) => ({
            SlideId: slide.SlideId,
            AdditionalDetails: {},
          })),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to save selected slides");
      }

      onSelect(selectedSlidesArray);
      onConfirm();
      handleClose();
    } catch (error) {
      console.error("Error saving selected slides:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          minHeight: "600px",
          maxHeight: "80vh",
          borderRadius: "12px",
          background: "linear-gradient(0deg, #CEE1FD 8.33%, #CCF6FF 91.67%)",
          boxShadow: "0px 2px 40px 0px rgba(34, 121, 245, 0.15)",
        },
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Select Slides</Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body2" color="text.secondary">
              {getSelectedCount()} slides selected
            </Typography>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ mt: 1 }}>
            {cases.map((caseItem) => (
              <Card key={caseItem.CaseId} variant="outlined" sx={{ mb: 2 }}>
                <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      borderRadius: "20px",
                      cursor: "pointer",
                      "&:hover": { bgcolor: "action.hover" },
                    }}
                    onClick={() => handleCaseExpand(caseItem.CaseId)}
                  >
                    <IconButton size="small" sx={{ mr: 1 }}>
                      {expandedCases[caseItem.CaseId] ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                    <Typography sx={{ flexGrow: 1 }}>
                      {caseItem.CaseName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {selectedSlides[caseItem.CaseId]?.length || 0} selected
                    </Typography>
                  </Box>

                  <Collapse in={expandedCases[caseItem.CaseId]}>
                    <Box sx={{ px: 2, pb: 2 }}>
                      {loadingSlides[caseItem.CaseId] ? (
                        <Box display="flex" justifyContent="center" p={2}>
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <>
                          <Grid container spacing={2}>
                            {caseSlides[caseItem.CaseId]?.map((slide) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={slide.SlideId}
                              >
                                <Card
                                  variant="outlined"
                                  sx={{
                                    position: "relative",
                                    cursor: "pointer",
                                    "&:hover": { boxShadow: 1 },
                                  }}
                                  onClick={() =>
                                    handleSlideSelect(caseItem.CaseId, slide)
                                  }
                                >
                                  <Box sx={{ position: "relative" }}>
                                    <img
                                      src={`https://path-image.s3.amazonaws.com/${slide.Thumbnail}`}
                                      alt={slide.SlideName}
                                      style={{
                                        width: "100%",
                                        height: "120px",
                                        objectFit: "cover",
                                      }}
                                    />
                                    <Checkbox
                                      checked={isSlideSelected(
                                        caseItem.CaseId,
                                        slide.SlideId
                                      )}
                                      sx={{
                                        position: "absolute",
                                        top: 8,
                                        right: 8,
                                        bgcolor: "background.paper",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </Box>
                                  <CardContent sx={{ py: 1 }}>
                                    <Typography variant="body2" noWrap>
                                      {slide.SlideName}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>

                          {/* Slides Pagination */}
                          {slideTotalPages[caseItem.CaseId] > 1 && (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: 2,
                              }}
                            >
                              <Pagination
                                count={slideTotalPages[caseItem.CaseId]}
                                page={slidePages[caseItem.CaseId] || 1}
                                onChange={(_, page) =>
                                  handleSlidePagination(caseItem.CaseId, page)
                                }
                                color="primary"
                                size="small"
                              />
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </Collapse>
                </CardContent>
              </Card>
            ))}

            {/* Cases Pagination */}
            {caseTotalPages > 1 && (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Pagination
                  count={caseTotalPages}
                  page={casePage}
                  onChange={(_, page) => setCasePage(page)}
                  color="primary"
                  size="medium"
                />
              </Box>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleClose} color="inherit" disabled={submitting}>
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={getSelectedCount() === 0 || submitting}
        >
          {submitting ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            `Confirm Selection (${getSelectedCount()})`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSlidesModal;
