// CreateMyCaseBoxModal.jsx
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Backdrop,
  Box,
  Modal,
  IconButton,
  Fade,
  Typography,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../../env";

export default function CreateMyCaseBoxModal({
  open,
  setOpen,
  setRefresh,
  isEditMode,
  setIsEditMode,
  caseData,
  isInfoMode = false,
}) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      CaseBoxName: "",
      AdditionalDetails: {},
      Status: true,
    },
  });

  const [isSaving, setIsSaving] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([
    { key: "", value: "" },
  ]);

  const handleClose = () => {
    if (setIsEditMode) {
      setIsEditMode(false);
    }
    setOpen(false);
  };

  useEffect(() => {
    if ((isEditMode || isInfoMode) && caseData) {
      reset({
        CaseBoxName: caseData.CaseBoxName || "",
        AdditionalDetails: caseData.AdditionalDetails || {},
        Status: caseData.Status || true,
      });

      // Convert AdditionalDetails object to array of key-value pairs
      if (caseData.AdditionalDetails) {
        const details = Object.entries(caseData.AdditionalDetails).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
        setAdditionalFields(
          details.length > 0 ? details : [{ key: "", value: "" }]
        );
      }
    } else {
      reset({
        CaseBoxName: "",
        AdditionalDetails: {},
        Status: true,
      });
      setAdditionalFields([{ key: "", value: "" }]);
    }
  }, [isEditMode, isInfoMode, caseData, reset]);

  const textFieldStyles = {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "none",
    height: "40px",
    right: "6px",
    left: "2px",
    width: "94%",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  };

  const handleAddField = () => {
    setAdditionalFields([...additionalFields, { key: "", value: "" }]);
  };

  const handleRemoveField = (index) => {
    const newFields = additionalFields.filter((_, i) => i !== index);
    setAdditionalFields(
      newFields.length > 0 ? newFields : [{ key: "", value: "" }]
    );
  };

  const handleFieldChange = (index, field, value) => {
    const newFields = [...additionalFields];
    newFields[index][field] = value;
    setAdditionalFields(newFields);
  };

  const onSubmit = (formData) => {
    setIsSaving(true);

    // Convert additionalFields array to object
    const additionalDetails = additionalFields.reduce((acc, field) => {
      if (field.key && field.value) {
        acc[field.key] = field.value;
      }
      return acc;
    }, {});

    const submissionData = {
      ...formData,
      AdditionalDetails: additionalDetails,
      TotalNumberOfSlides: 0,
    };

    const requestUrl = isEditMode
      ? `${API_URL}case_box/${caseData.CaseBoxId}`
      : `${API_URL}case_box`;
    const requestMethod = isEditMode ? "PUT" : "POST";

    fetch(requestUrl, {
      method: requestMethod,
      body: JSON.stringify(submissionData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        reset();
        setRefresh((prev) => !prev);
        handleClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const renderTextField = (name, label, type = "text", gridSize = 6) => (
    <Grid item xs={gridSize}>
      <label className="label">
        {label}
        {!isInfoMode && "*"}
      </label>
      <Controller
        name={name}
        control={control}
        rules={{ required: !isInfoMode && `${label} is required` }}
        render={({ field }) => (
          <TextField
            fullWidth
            type={type}
            variant="outlined"
            sx={textFieldStyles}
            {...field}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            disabled={isInfoMode}
            required={!isInfoMode}
          />
        )}
      />
    </Grid>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="casebox-modal-box" sx={{ p: 4 }}>
            {!isInfoMode && (
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: -10,
                  right: -10,
                  width: 25,
                  height: 25,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  color: "black",
                  padding: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                }}
              >
                <CloseIcon />
              </IconButton>
            )}

            <Typography
              style={{ textAlign: "center", marginBottom: 20, fontWeight: 500 }}
              id="transition-modal-title"
              variant="h5"
              component="h2"
            >
              {isInfoMode
                ? "Case Box Details"
                : isEditMode
                ? "Edit Case Box"
                : "Create New Case Box"}
            </Typography>

            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container spacing={2}>
                {renderTextField("CaseBoxName", "Case Box Name", "text", 12)}

                <Grid item xs={12}>
                  <label className="label">Additional Details</label>
                  {additionalFields.map((field, index) => (
                    <Grid container spacing={2} key={index} sx={{ mt: 1 }}>
                      <Grid item xs={5.5}>
                        <TextField
                          fullWidth
                          placeholder="Key"
                          value={field.key}
                          onChange={(e) =>
                            handleFieldChange(index, "key", e.target.value)
                          }
                          sx={textFieldStyles}
                          disabled={isInfoMode}
                        />
                      </Grid>
                      <Grid item xs={5.5}>
                        <TextField
                          fullWidth
                          placeholder="Value"
                          value={field.value}
                          onChange={(e) =>
                            handleFieldChange(index, "value", e.target.value)
                          }
                          sx={textFieldStyles}
                          disabled={isInfoMode}
                        />
                      </Grid>
                      {!isInfoMode && (
                        <Grid
                          item
                          xs={1}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          <IconButton
                            onClick={() => handleRemoveField(index)}
                            disabled={additionalFields.length === 1}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                  {!isInfoMode && (
                    <Button
                      variant="outlined"
                      onClick={handleAddField}
                      sx={{ mt: 2 }}
                    >
                      Add Field
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>

            <div className="submit-div">
              <div className="cancel-div" onClick={handleClose}>
                Cancel
              </div>
              {!isInfoMode && (
                <div className="save-div" onClick={handleSubmit(onSubmit)}>
                  {isSaving && <div className="spinner"></div>}
                  {isSaving ? "Saving..." : "Save"}
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
